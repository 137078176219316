<template>
  <div class="modal-email-report">
    <b-modal
      id="modal-email-report"
      header-bg-variant="primary"
      header-text-variant="white"
      button-size="sm"
      hide-footer
      :title="$t('modals.emailReport')"
      :cancel-title="$t('form.buttons.cancelButton')"
      :ok-title="$t('form.buttons.sendButton')"
      @cancel="loading = false"
      @hide="loading = false"
      @ok.prevent="handleSubmit"
    >
      <b-form @submit.prevent="handleSubmit">
        <p class="text-dark-darken">{{ $t('form.describeTexts.emailReport') }}</p>
        <b-form-input
          name="email"
          type="email"
          id="report-email"
          size="sm"
          ref="email-report"
          v-model="email"
          required
          :placeholder="$t('form.placeholders.email')"
          :disabled="loading"
          @input="error = false"
        ></b-form-input>
        <uello-notification
          class="mw-100"
          :closeable="false"
          :collapsible="false"
          example=""
          :message="errorMessage"
          v-if="error"
          type="error"
          title="Houve um problema!"
        />

        <div class="d-flex justify-content-between align-items-center w-100 mt-4">
          <uello-button
            color="gray"
            :disabled="loading"
            :text="$t('form.buttons.cancelButton')"
            @click="$bvModal.hide('modal-email-report')"
          >
          </uello-button>
          <b-button
            class="min-width-report"
            variant="primary"
            type="submit"
            size="sm"
            :disabled="loading"
          >
            <span class="text-white" v-if="!loading">{{ $t('form.buttons.sendButton') }}</span>
            <Loading :height="20" :width="20" v-else />
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import api from '@/services/api';
import { formatFilters } from '@/utils/agGrid';
import { UelloButton, UelloNotification } from '@uello/componentello';
import Loading from '@/components/Loading.vue';

export default {
  name: 'modal-email-report',
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  components: {
    UelloButton,
    UelloNotification,
    Loading,
  },
  data() {
    return {
      email: '',
      loading: false,
      error: false,
      errorMessage: '',
    };
  },
  methods: {
    async handleSubmit() {
      if (!this.email) {
        this.errorMessage = this.$t('messages.emailRequired');
        this.error = true;
        this.$refs['email-report'].focus();
      } else {
        this.loading = true;

        try {
          const { data: response } = await api.post('/orders/reports', {
            email: this.email,
            filters: formatFilters(this.filters),
          });

          if (!response.success) {
            throw new Error();
          } else {
            this.$toast.success(this.$t('messages.reportSuccess'));
            this.$bvModal.hide('modal-email-report');
          }
        } catch (error) {
          this.$toast.error(this.$t('messages.reportFail'));
        }

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
button.min-width-report {
  min-width: 80px;
}
</style>
